const initState = {
  navigationDirection: null,
  lastInquiryCreatedAt: null,
  inquiryError: null,
  loading: false,
};

const courseInquiryReducer = (state = initState, action) => {
  switch (action.type) {
    case "INQUIRY_COMPLETE_ERROR":
      console.error(action.error);
      return {
          ...state,
          contactError: "Complete Error",
          loading: false,
      };
  case "INQUIRY_COMPLETE_LOADING":
      return {
          ...state,
          loading: action.payload,
      };
  case "INQUIRY_COMPLETE_SUCCESS":
      return {
          ...state,
          contactError: null,
      };
  case "INQUIRY_SET_LAST_INQUIRY_CREATED_TIME":
    return {
        ...state,
        navigationDirection: action.payload.navigationDirection,
        lastInquiryCreatedAt: action.payload.date,
    };
    default:
      return state;
  }
};

export default courseInquiryReducer;
