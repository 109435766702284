import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { getFirestore } from "redux-firestore";
import { getFirebase } from "react-redux-firebase";

import rootReducer from "./reducer";
import { composeWithDevTools } from "redux-devtools-extension";

export const configureStore = (initialStore, browserHistory) => {
  const composedEnhancer = composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }))
  );
  const store = createStore(rootReducer, composedEnhancer);
  return store;
};

export default configureStore;
