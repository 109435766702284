const initState = {
  authError: null,
  loading: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      console.log("login failed");
      if (action.error.code === "auth/user-not-found") {
        return {
          ...state,
          authError: "User not found, check your email address",
          loading: false,
        };
      } else if (action.error.code === "auth/wrong-password") {
        return {
          ...state,
          authError: "Wrong Password",
          loading: false,
        };
      }
      return {
        ...state,
        authError: "Login Error",
        loading: false,
      };
    case "LOGIN_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "LOGIN_SUCCESS":
      console.log("login succes");
      return {
        ...state,
        authError: null,
      };
    case "SIGNOUT_SUCCESS":
      console.log("sigout success");
      return state;
    default:
      return state;
  }
};

export default authReducer;
