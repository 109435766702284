const initialState = {
  loading: false,
  gbpError: null,
};

const gbpReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GBP_RATE_ERROR":
      return {
        ...state,
        gbpError: "GBP Error",
        loading: false,
      };
    case "GBP_RATE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "GBP_RATE_SUCCESS":
      return {
        ...state,
        gbpError: null,
      };
    default:
      return state;
  }
};

export default gbpReducer;
