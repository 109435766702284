import { Button } from "@windmill/react-ui";

const PrevIcon = function PrevIcon(props) {
    return (
      <svg {...props} aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
        <path
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clipRule="evenodd"
          fillRule="evenodd"
        ></path>
      </svg>
    )
}
  
const NextIcon = function NextIcon(props) {
    return (
      <svg {...props} aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
        <path
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
          fillRule="evenodd"
        ></path>
      </svg>
    )
}

const NavigationButton = function NavigationButton({
    onClick,
    disabled,
    directionIcon,
}) {
    const ariaLabel = directionIcon === 'prev' ? 'First' : 'Next'

    const icon = directionIcon === 'prev' ? PrevIcon : NextIcon

    return (
        <Button
            size="large"
            layout="link"
            iconLeft={ directionIcon === 'prev' ? icon : undefined }
            iconRight={ directionIcon !== 'prev' ? icon : undefined }
            onClick={onClick}
            disabled={disabled}
            // aria-label={ariaLabel}
        >
            {ariaLabel}
        </Button>
    )
}

const BasicPagination = ({
    label,
    isFirstPage,
    isLastPage,
    handleFirstPageClick,
    handleNextClick
}) => {
    return (
        <div className="flex mt-2 sm:mt-auto sm:justify-end">
            <nav aria-label={label}>
                <ul className="inline-flex items-center">
                    {
                        !isFirstPage && 
                        <li>
                            <NavigationButton
                                directionIcon="prev"
                                onClick={handleFirstPageClick}
                            />
                        </li>
                    }
                    {
                        !isLastPage && 
                        <li>
                            <NavigationButton
                                directionIcon="next"
                                onClick={handleNextClick}
                            />
                        </li>
                    }
                </ul>
            </nav>
        </div>
    );
}

export default BasicPagination;