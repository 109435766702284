import React, { Fragment, useEffect, useState } from "react";

// import CTA from "../components/CTA";
import PageTitle from "../components/Typography/PageTitle";
// import SectionTitle from "../components/Typography/SectionTitle";
import {
  Table,
  TableHeader,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  TableFooter,
  Pagination,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "@windmill/react-ui";
import Spinner from "../components/spinner";
import BasicPagination from "../components/BasicPagination";

// import { MailIcon } from "../icons";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { OutlinePersonIcon, PhoneIcon, CalendarIcon, } from "../icons";
import moment from "moment";

import { toggleComplete, setLastContactCreatedTime } from '../redux/actions/contactActions';

function capitalize(str) {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}

function Contacts(props) {
  const { contacts, auth, contactError, loading, navigationDirection, lastContactCreatedAt } = props;
  // state ---
  const [filteredContacts, setfilteredContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // console.log(contacts[0]);
  useEffect(() => {
    if (!auth.uid) {
      props.history.push("/login");
    }
  }, [auth]);

  const filterData = (query) => {
    let out = [];
    for (let i = 0; i < contacts.length; i++) {
      const inq = contacts[i];
      if (
        inq["firstName"]?.toLowerCase().includes(query) ||
        inq["lastName"]?.toLowerCase().includes(query) ||
        inq["mobileNumber"]?.toLowerCase().includes(query) ||
        inq["email"]?.toLowerCase().includes(query)
      ) {
        out.push(inq);
      }
    }
    setfilteredContacts(out);
  };

  useEffect(() => {
    if (searchQuery !== "") {
      filterData(searchQuery);
    } else {
      setfilteredContacts(contacts ?? []);
    }
  }, [searchQuery, contacts]);

  useEffect(() => {
    if (isLoaded(contacts)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [contacts]);

  const handleNextClick = () => {
    var lastCreatedAtMilliseconds = null;
    var contact = contacts[contacts.length - 1];

    if( contact.createdAt && contact.createdAt.seconds ){
      lastCreatedAtMilliseconds = contact.createdAt.seconds * 1000;
      let lastCreatedAtDate = new Date(lastCreatedAtMilliseconds);
      
      props.setLastContactCreatedTime( 'next', lastCreatedAtDate)
    }
  }

  const handleFirstPageClick = () => {
    props.setLastContactCreatedTime( null, null)
  }

  return (
    <Fragment>
      <PageTitle>Contact Requests</PageTitle>
      {/* <input type="text"  /> */}
      <div className="mb-8">
        <div class="grid grid-cols-4 gap-4">
          
          <div className="relative focus-within:text-primary-light">
            <div className="absolute inset-y-0 flex items-center pt-4 pl-2">
              <OutlinePersonIcon className="w-4 h-4 text-primary" aria-hidden="true" />
            </div>
            <Label>
              <span>Name</span>
              <Input
                onChange={(e) => setSearchName(e.target.value)}
                className="pl-8 text-gray-700"
              />
            </Label>
          </div>

          <div className="relative focus-within:text-primary-light">
            <div className="absolute inset-y-0 flex items-center pt-4 pl-2">
              <PhoneIcon className="w-4 h-4 text-primary" aria-hidden="true" />
            </div>
            <Label>
              <span>Mobile Number</span>
              <Input
                onChange={(e) => setSearchMobile(e.target.value)}
                className="pl-8 text-gray-700"
              />
            </Label>
          </div>

          <div className="relative focus-within:text-primary-light">
            <div className="absolute inset-y-0 flex items-center pt-4 pl-2">
              <CalendarIcon className="w-4 h-4 text-primary" aria-hidden="true" />
            </div>
            <Label>
              <span>From</span>
              <Input
                onChange={(e) => setFromDate(e.target.value)}
                className="pl-8 text-gray-700"
                type="date"
              />
            </Label>
          </div>
          
          <div className="relative focus-within:text-primary-light">
            <div className="absolute inset-y-0 flex items-center pt-4 pl-2">
              <CalendarIcon className="w-4 h-4 text-primary" aria-hidden="true" />
            </div>
            <Label>
              <span>To</span>
              <Input
                onChange={(e) => setToDate(e.target.value)}
                className="pl-8 text-gray-700"
                type="date"
              />
            </Label>
          </div>
          
        </div>
      </div>

      <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
        <TableContainer>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Mobile No</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Completed</TableCell>
                <TableCell>View</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading && (
                <>
                  <TableRow>
                    <TableCell>
                      <div className="h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <Button disabled size="small">
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className="h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <Button disabled size="small">
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              )}
              {filteredContacts &&
                filteredContacts.map((contact) => {
                  return (
                    <TableRow key={contact.id}>
                      <TableCell>
                        <div className="flex items-center text-sm">
                          {/* <Avatar src="/img/avatar-1.jpg" alt="Judith" /> */}
                          <span className="ml-2 font-semibold">
                            {capitalize(contact.firstName)}{" "}
                            {capitalize(contact.lastName)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="text-sm">{contact.number}</span>
                      </TableCell>
                      <TableCell>
                        <span className="text-sm">{ contact.createdAt ? moment(contact.createdAt.seconds * 1000).format('YYYY-MM-DD hh:mm A') : '-' }</span>
                      </TableCell>
                      <TableCell>
                        <span className="text-sm">{contact.isCompleted ? '✓' : '-'}</span>
                      </TableCell>
                      <TableCell>
                        <ViewModal onSubmit={props.toggleComplete} loading={loading} contactError={contactError} {...contact} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TableFooter>
            <BasicPagination
              isFirstPage={navigationDirection === null}
              isLastPage={ ( navigationDirection === null || navigationDirection === 'next' ) && ( !contacts || contacts.length < 10) }
              handleFirstPageClick={handleFirstPageClick}
              handleNextClick={handleNextClick}
            />
            {/* <Pagination
              totalResults={10}
              resultsPerPage={10}
              onChange={() => {}}
              // label="Table navigation"
            /> */}
          </TableFooter>
        </TableContainer>
      </div>
    </Fragment>
  );
}

const ViewModal = ({
  onSubmit,
  loading,
  contactError,
  id,
  firstName,
  lastName,
  createdAt,
  email,
  number,
  subject,
  comments,
  isCompleted,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  function openModal() {
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <Fragment>
      <Button onClick={openModal} size="small">
        View
      </Button>
      <Modal onClose={closeModal} isOpen={isModalOpen}>
        {/* <ModalHeader>
          <span className="text-sm font-light">
            {moment(createdAt.toString()).format("lll")}
          </span>
        </ModalHeader> */}
        <ModalBody>
          <div className="py-4 text-primary">Contact Details</div>
          <div className="grid grid-flow-row grid-cols-2 gap-5">
            <div>
              <span>First Name</span>
              <div className="text-xl">{capitalize(firstName)}</div>
            </div>
            <div>
              <span>Last Name</span>
              <div className="text-xl">{capitalize(lastName)}</div>
            </div>

            <div>
              <span>Email Address</span>
              <div className="text-xl">{email ? email : "N/A"}</div>
            </div>
          </div>
          <div className="pt-5">
            <span>Mobile Number</span>
            <div className="text-xl">{number ? number : "N/A"}</div>
          </div>
          <div className="pt-5">
            <span>Subject</span>
            <div className="text-xl">{subject ? subject : "N/A"}</div>
          </div>
          <div className="pt-5">
            <span>Comments</span>
            <div className="text-xl">{comments ? comments : "N/A"}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button 
            className="w-full sm:w-auto"
            disabled={loading}
            onClick={() => {
              onSubmit(id, !isCompleted);
              closeModal();
            }}
          >
            { loading ? <Spinner /> : ( isCompleted ? 'Mark as Incomplete' : 'Complete') }
          </Button>

          <Button
            className="w-full sm:w-auto"
            layout="outline"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    contacts: state.firestore.ordered.contacts,
    contactError: state.contact.contactError,
    loading: state.contact.loading,
    navigationDirection: state.contact.navigationDirection,
    lastContactCreatedAt: state.contact.lastContactCreatedAt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleComplete: (id, value) => dispatch(toggleComplete(id, value)),
    setLastContactCreatedTime: (direction, date) => dispatch(setLastContactCreatedTime(direction, date)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let params = {
      collection: "contacts",
      orderBy: [
        ["createdAt", "desc"]
      ],
      limit: 10,
    };

    let where = [];
    let startAt = [];

    // search
    // if(  )

    // order
    if( props.navigationDirection === 'next' ){
      startAt.push(props.lastContactCreatedAt);
    }

    // append
    if( where.length > 0 ){
      params.where = where;
    }
    if( startAt.length > 0 ){
      params.startAt = startAt;
    }

    return [params]; 
  })
)(Contacts);
