import React, { Fragment, useEffect, useState } from "react";

// import CTA from "../components/CTA";
import PageTitle from "../components/Typography/PageTitle";
// import SectionTitle from "../components/Typography/SectionTitle";
import {
  Table,
  TableHeader,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  TableFooter,
  Pagination,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "@windmill/react-ui";
import Spinner from "../components/spinner";
import BasicPagination from "../components/BasicPagination";

// import { MailIcon } from "../icons";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import moment from "moment";
import { SearchIcon } from "../icons";

import { toggleComplete, setLastInquiryCreatedTime } from '../redux/actions/inquiryActions';

// import moment from "moment";

function capitalize(str) {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}

function CourseInquiries(props) {
  const { inquiries, auth, inquiryError, loading, navigationDirection, lastInquiryCreatedAt } = props;
  // state ---
  const [filteredInquiries, setFilteredInquiries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // console.log(inquiries[0]);
  useEffect(() => {
    if (!auth.uid) {
      props.history.push("/login");
    }
  }, [auth, props.history]);

  const filterData = (query) => {
    let out = [];
    for (let i = 0; i < inquiries.length; i++) {
      const inq = inquiries[i];
      if (
        inq["firstName"]?.toLowerCase().includes(query) ||
        inq["lastName"]?.toLowerCase().includes(query) ||
        inq["mobileNumber"]?.toLowerCase().includes(query) ||
        inq["email"]?.toLowerCase().includes(query) ||
        inq["course"]?.toLowerCase().includes(query)
      ) {
        out.push(inq);
      }
    }
    setFilteredInquiries(out);
  };

  useEffect(() => {
    if (searchQuery !== "") {
      filterData(searchQuery);
    } else {
      setFilteredInquiries(inquiries ?? []);
    }
  }, [searchQuery, inquiries]);

  useEffect(() => {
    if (isLoaded(inquiries)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [inquiries]);

  const handleNextClick = () => {
    var lastCreatedAtMilliseconds = null;
    var inquiry = inquiries[inquiries.length - 1];

    if( inquiry.createdAt && inquiry.createdAt.seconds ){
      lastCreatedAtMilliseconds = inquiry.createdAt.seconds * 1000;
      let lastCreatedAtDate = new Date(lastCreatedAtMilliseconds);
      
      props.setLastInquiryCreatedTime( 'next', lastCreatedAtDate)
    }
  }

  const handleFirstPageClick = () => {
    props.setLastInquiryCreatedTime( null, null)
  }

  return (
    <Fragment>
      <PageTitle>Course Inquiries</PageTitle>
      {/* <input type="text"  /> */}
      <div className="mb-8">
        <div className="relative w-full max-w-xl mr-6 focus-within:text-primary-light">
          <div className="absolute inset-y-0 flex items-center pl-2">
            <SearchIcon className="w-4 h-4 text-primary" aria-hidden="true" />
          </div>
          <Input
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-8 text-gray-700"
            placeholder="Search for Inquiries"
            aria-label="Search"
          />
        </div>
      </div>

      <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
        <TableContainer>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Completed</TableCell>
                <TableCell>View</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading && (
                <>
                  <TableRow>
                    <TableCell>
                      <div className="h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <Button disabled size="small">
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className="h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="w-full h-6 bg-gray-100 rounded-md dark:bg-gray-700 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <Button disabled size="small">
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              )}
              {filteredInquiries &&
                filteredInquiries.map((inquiry) => {
                  return (
                    <TableRow key={inquiry.id}>
                      <TableCell>
                        <div className="flex items-center text-sm">
                          {/* <Avatar src="/img/avatar-1.jpg" alt="Judith" /> */}
                          <span className="ml-2 font-semibold">
                            {capitalize(inquiry.firstName)}{" "}
                            {capitalize(inquiry.lastName)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="text-sm">{inquiry.course}</span>
                      </TableCell>
                      <TableCell>
                        <span className="text-sm">{ inquiry.createdAt ? moment(inquiry.createdAt.seconds * 1000).format('YYYY-MM-DD hh:mm A') : '-' }</span>
                      </TableCell>
                      <TableCell>
                        <span className="text-sm">{inquiry.isCompleted ? '✓' : '-'}</span>
                      </TableCell>
                      <TableCell>
                        <ViewModal onSubmit={props.toggleComplete} loading={loading} inquiryError={inquiryError} {...inquiry} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TableFooter>
            <BasicPagination
              isFirstPage={navigationDirection === null}
              isLastPage={ ( navigationDirection === null || navigationDirection === 'next' ) && ( !inquiries || inquiries.length < 10) }
              handleFirstPageClick={handleFirstPageClick}
              handleNextClick={handleNextClick}
            />

            {/* <Pagination
              totalResults={10}
              resultsPerPage={10}
              onChange={() => {}}
              // label="Table navigation"
            /> */}
          </TableFooter>
        </TableContainer>
      </div>
    </Fragment>
  );
}

const ViewModal = ({
  onSubmit,
  loading,
  inquiryError,
  id,
  firstName,
  lastName,
  createdAt,
  email,
  mobileNumber,
  degree,
  course,
  country,
  designation,
  workingExperienceYears,
  isCompleted,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  function openModal() {
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <Fragment>
      <Button onClick={openModal} size="small">
        View
      </Button>
      <Modal onClose={closeModal} isOpen={isModalOpen}>
        <ModalHeader>
          <span className="text-sm font-light">
            {moment(createdAt.toString()).format("lll")}
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="py-4 text-primary">Personal Information</div>
          <div className="grid grid-flow-row grid-cols-2 gap-5">
            <div>
              <span>First Name</span>
              <div className="text-xl">{capitalize(firstName)}</div>
            </div>
            <div>
              <span>Last Name</span>
              <div className="text-xl">{capitalize(lastName)}</div>
            </div>
            <div>
              <span>Mobile No</span>
              <div className="text-xl">{mobileNumber}</div>
            </div>
            <div>
              <span>Email Address</span>
              <div className="text-xl">{email ? email : "N/A"}</div>
            </div>
            <div>
              <span>Designation</span>
              <div className="text-xl">{designation ? designation : "N/A"}</div>
            </div>
            <div>
              <span>Working Experience ~ Years</span>
              <div className="text-xl">
                {workingExperienceYears ? workingExperienceYears : "N/A"}
              </div>
            </div>
            <div>
              <span>Country</span>
              <div className="text-xl">{country}</div>
            </div>
          </div>
          <div className="pt-5">
            <div className="py-4 text-primary">About Programme</div>
            <div>
              <span>Degree</span>
              <div className="text-xl">{degree}</div>
            </div>
            <div className="pt-5">
              <span>Course</span>
              <div className="text-xl">{course}</div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button 
            className="w-full sm:w-auto"
            disabled={loading}
            onClick={() => {
              onSubmit(id, !isCompleted);
              closeModal();
            }}
          >
            { loading ? <Spinner /> : ( isCompleted ? 'Mark as Incomplete' : 'Complete') }
          </Button>

          <Button
            className="w-full sm:w-auto"
            layout="outline"
            onClick={closeModal}
          >
            Cancel
          </Button>
          {/* <Button className="w-full sm:w-auto">Accept</Button> */}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    inquiries: state.firestore.ordered.inquiries,
    inquiryError: state.inquiry.inquiryError,
    loading: state.inquiry.loading,
    navigationDirection: state.inquiry.navigationDirection,
    lastInquiryCreatedAt: state.inquiry.lastInquiryCreatedAt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleComplete: (id, value) => dispatch(toggleComplete(id, value)),
    setLastInquiryCreatedTime: (navigationDirection, date) => dispatch(setLastInquiryCreatedTime(navigationDirection, date)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let params = {
      collection: "inquiries",
      orderBy: ["createdAt", "desc"],
      limit: 10,
    };

    if( props.navigationDirection === 'next' ){
      params.startAt = props.lastInquiryCreatedAt
    }
    return [params];
  })
)(CourseInquiries);
