export const toggleComplete = (id, value) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const firestore = firebase.firestore(); 
        dispatch({ type: "INQUIRY_COMPLETE_LOADING", payload: true });
        
        firestore.collection('inquiries').doc(id).update({
            isCompleted: value
        })
        .then(() => {
            dispatch({ type: "INQUIRY_COMPLETE_SUCCESS" });
            dispatch({ type: "INQUIRY_COMPLETE_LOADING", payload: false });
        })
        .catch((err) => {
            dispatch({ type: "INQUIRY_COMPLETE_ERROR", error: err });
        });
    };
};

export const setLastInquiryCreatedTime = (navigationDirection, date) => {
    return (dispatch, getState) => {
        dispatch({ type: "INQUIRY_SET_LAST_INQUIRY_CREATED_TIME", payload: {
            navigationDirection: navigationDirection,
            date: date
        } });
    };
}