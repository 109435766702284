import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import authReducer from "./reducers/authReducer";
import contactReducer from "./reducers/contactReducer";
import courseInquiryReducer from "./reducers/courseInquiryReducer";
import gbpReducer from "./reducers/gbpReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  contact: contactReducer,
  gbpRate: gbpReducer,
  inquiry: courseInquiryReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});

export default rootReducer;
