const initState = {
    navigationDirection: null,
    lastContactCreatedAt: null,
    contactError: null,
    loading: false,
};
  
const contactReducer = (state = initState, action) => {
    switch (action.type) {
        case "CONTACT_COMPLETE_ERROR":
            console.error(action.error);
            return {
                ...state,
                contactError: "Complete Error",
                loading: false,
            };
        case "CONTACT_COMPLETE_LOADING":
            return {
                ...state,
                loading: action.payload,
            };
        case "CONTACT_COMPLETE_SUCCESS":
            return {
                ...state,
                contactError: null,
            };
        case "CONTACT_SET_LAST_CONTACT_CREATED_TIME":
            return {
                ...state,
                navigationDirection: action.payload.navigationDirection,
                lastContactCreatedAt: action.payload.date,
            };
        default:
        return state;
    }
};
  
export default contactReducer;
  