// Replace this with your own config details
const firebaseConfig = {
  apiKey: "AIzaSyD36bQ-tvRHHVf5Nz7YThnKbGbTOOqIeX8",
  authDomain: "next-lk.firebaseapp.com",
  databaseURL: "https://next-lk.firebaseio.com",
  projectId: "next-lk",
  storageBucket: "next-lk.appspot.com",
  messagingSenderId: "843865230278",
  appId: "1:843865230278:web:d0c9801de458919abb40c7",
  measurementId: "G-ZRCDWYD2Q0",
};

export default firebaseConfig;
