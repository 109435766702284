import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Windmill } from "@windmill/react-ui";
import { theme } from "./theme/theme";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { Provider, useSelector } from "react-redux";
import { isLoaded, ReactReduxFirebaseProvider } from "react-redux-firebase";
import { SidebarProvider } from "./context/SidebarContext";
import configureStore from "./redux/store";
import { createFirestoreInstance } from "redux-firestore";

import firebaseConfig from "./firebase";

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots: true });

const store = configureStore();

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <div
        style={{ height: "100vh", width: "100%" }}
        className="flex items-center justify-center"
      >
        <svg
          className="w-10 h-10 mr-3 -ml-1 animate-spin text-primary"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx={12}
            cy={12}
            r={10}
            stroke="currentColor"
            strokeWidth={4}
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>
    );
  return children;
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      firebase={firebase}
      config={{
        userProfile: "users",
        useFirestoreForProfile: true,
      }}
      dispatch={store.dispatch}
      createFirestoreInstance={createFirestoreInstance}
    >
      <SidebarProvider>
        <Windmill theme={theme} usePreferences>
          <AuthIsLoaded>
            <App />
          </AuthIsLoaded>
        </Windmill>
      </SidebarProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
