import React from "react";

import Login from "./pages/Login";
import { ToastProvider } from "react-toast-notifications";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Layout from "./containers/Layout";

function App() {
  return (
    <>
      <ToastProvider autoDismiss placement="bottom-right">
        <Router>
          <Switch>
            <Route component={Login} path="/login" />
            <Route component={Layout} path="/app" />
            <Redirect to="login" from="/" />
          </Switch>
        </Router>
      </ToastProvider>
    </>
  );
}

export default App;
