import React, { useEffect } from "react";
import { connect } from "react-redux";
import { signIn } from "../redux/actions/authActions";

import { Label, Button, Input } from "@windmill/react-ui";

import { useForm } from "react-hook-form";
import { Redirect } from "react-router";
import Spinner from "../components/spinner";
import { useToasts } from "react-toast-notifications";
const boxStyles = {
  backdropFilter: "saturate(180%) blur(20px)",
  background: "rgba(0,0,0,.8)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  transition: "all .5s cubic-bezier(.68,-.27,.37,1.25) !important",
};

const Login = (props) => {
  const { auth, authError, loading } = props;
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    props.signIn(data);
  };

  useEffect(() => {
    if (authError) {
      addToast(authError, {
        appearance: "error",
      });
    }
  }, [authError, addToast]);

  if (auth && auth.uid) {
    return <Redirect to="/app" />;
  }

  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div style={boxStyles} className="h-32 md:h-auto md:w-1/2 ">
            <img src="/next-logo.png" alt="Logo" className="mx-auto w-36" />
          </div>
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Login
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Label>
                  <div className="flex items-baseline justify-between">
                    <span>Email</span>
                    <span className="text-xs text-primary">
                      {errors && errors.email?.message}
                    </span>
                  </div>
                  <Input
                    {...register("email", { required: "Email Required" })}
                    className="mt-1"
                    type="email"
                    placeholder="john@doe.com"
                  />
                </Label>

                <Label className="mt-4">
                  <div className="flex items-baseline justify-between">
                    <span>Password</span>
                    <span className="text-xs text-primary">
                      {errors && errors.password?.message}
                    </span>
                  </div>
                  <Input
                    {...register("password", { required: "Password Required" })}
                    className="mt-1"
                    type="password"
                    placeholder="***************"
                  />
                </Label>

                <Button
                  type="submit"
                  layout="primary"
                  className="mt-4"
                  block
                  to="/app"
                >
                  {loading ? <Spinner /> : "Log in"}
                </Button>
              </form>

              {/* <hr className="my-8" />

              <Button block layout="outline">
                <GithubIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                Github
              </Button>
              <Button className="mt-4" block layout="outline">
                <TwitterIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                Twitter
              </Button> */}

              {/* <p className="mt-4">
                <Link
                  className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                  to="/forgot-password"
                >
                  Forgot your password?
                </Link>
              </p>
              <p className="mt-1">
                <Link
                  className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                  to="/create-account"
                >
                  Create account
                </Link>
              </p> */}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (cred) => dispatch(signIn(cred)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
